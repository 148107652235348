import React from 'react'
import { useHistory } from "react-router-dom";
import { Box, Modal, Typography, Divider, styled, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const MyModal = styled(Modal)({
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
});

const MyIconButton = styled(IconButton)({
    position: "absolute",
    top: 8,
    right: 10,
    fontSize: 5
});

const PoliticasModal = () => {
    const history = useHistory();
    const handleClose = () => {
        history.goBack();
    };

    return (
        <MyModal
            open={true}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
        >
            <Box
                sx={{
                    maxWidth: 500,
                    maxHeight: 500,
                    bgcolor: "background.default",
                    color: "text.primary",
                    p: 4,
                    borderRadius: "10px",
                    overflow: "auto",
                    position: "relative"
                }}
            >

                <MyIconButton
                    onClick={handleClose}
                >
                    <CloseIcon />
                </MyIconButton>

                <Typography
                    id="modal-title"
                    variant="h6"
                    component="h2"
                    style={{
                        fontSize: "20px",
                        textAlign: "center",
                        fontWeight: "bold"
                    }}
                >
                    Políticas de CDT
                </Typography>

                <Divider sx={{ my: 2 }} />

                <Typography
                    id="modal-description"
                    variant="body1"
                    style={{
                        fontSize: 15,
                        fontFamily: "sans-serif",
                        fontWeight: "lighter",
                        padding: 10
                    }}
                >
                    <a href="/documents/PoliticaDePrivacidadCDT.pdf" target="_blank">
                        Políticas de Privacidad
                    </a>

                    <br />

                    <a href="/documents/PoliticaDeSeguridadDeLaInformacion.pdf" target="_blank">
                        Políticas de Seguridad de la Información
                    </a>

                    <br />

                    <a href="/documents/NormaDePropiedadIntelectual.pdf" target="_blank">
                        Norma de Propiedad Intelectual
                    </a>

                    <br />

                    <a href="/documents/NormaDeUsoActivosDeLaInformación.pdf" target="_blank">
                        Norma de Uso Aceptable de Activos de la Información
                    </a>
                </Typography>
            </Box>
        </MyModal >
    );
};

export default PoliticasModal;