import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import PoliticasModal from "routes/modules/Politicas/PoliticasModal";

import PerfectScrollbar from "react-perfect-scrollbar";
import makeStyles from "@material-ui/core/styles/makeStyles";

import CmtVertical from "../../../../../@coremat/CmtNavigation/Vertical";
import { adminSidebarNavs } from "../menus";

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: "100%",
    transition: "all 0.3s ease",
    ".Cmt-sidebar-fixed &, .Cmt-Drawer-container &": {
      height: "calc(100% - 167px)"
    },
    ".Cmt-modernLayout &": {
      height: "calc(100% - 72px)"
    },
    ".Cmt-miniLayout &": {
      height: "calc(100% - 91px)"
    },
    ".Cmt-miniLayout .Cmt-sidebar-content:hover &": {
      height: "calc(100% - 167px)"
    }
  }
}));

const SideBar = () => {
  const classes = useStyles();
  const location = useLocation();
  const [openPoliticasModal, setOpenPoliticasModal] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setOpenPoliticasModal(params.get("modal") === "true");
  }, [location]);

  return (
    <>
      <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
        <CmtVertical menuItems={adminSidebarNavs} />
        {/* Renderizo el modal */}
        {openPoliticasModal && <PoliticasModal />}
      </PerfectScrollbar>
    </>
  );
};

export default SideBar;
