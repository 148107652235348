import React, { useContext, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router";
import { useSelector } from "react-redux";
import Error404 from "./Pages/404";
import UserProfileView from "./modules/UserProfile/UserProfileView";
import UserProfileEdit from "./modules/UserProfile/UserProfileEdit";
import Intranet from "./Dasboards/Intranet";
import UsersModule from "./modules/Users";
import LicencesModule from "./modules/Licences";
import PermissionProvider from "../services/permissionProvider/PermissionProvider";
import ProjectsModule from "./modules/Projects";
import ProjectDetailView from "./Pages/ProjectDetailView";
import TrainingDetailView from "./Pages/TrainingDetailView";
import LicenceDetailView from "./Pages/LicenceDetailView";
import TrainingsModule from "./modules/Trainings";
import TimeTrackingModule from "./modules/TimeTracking/TimeTrackingLoader";
import TimeTrackingApproverModule from "./modules/TimeTracking/TimeTrackingApprover";
import TimeTrackingAdminModule from "./modules/TimeTracking/TimeTrackingAdmin";
import Login from "routes/Auth/Login";
import Benefits from "./modules/Benefits/Benefits";
import News from "./modules/News/News";
import AddNews from "./modules/News/AddNews";
import NewsDetailView from "./modules/News/NewsDetailView";
import EditNew from "./modules/News/EditNew";
import AdminAddHoursModule from "./modules/TimeTracking/TimeTrackingAdmin/AdminAddHoursModule/AdminAddHoursModule";
import AddHolidayHours from "./modules/Holiday/Holiday";
// import AddBenefits from "./modules/Benefits/AddBenefits";
import AppContext from "@jumbo/components/contextProvider/AppContextProvider/AppContext";
import { THEME_TYPES } from "@jumbo/constants/ThemeOptions";
import BenefitsDetailView from "./modules/Benefits/BenefitsDetailView";
// import EditBenefit from "./modules/Benefits/EditBenefit";
// import Vouchers from "./modules/Benefits/Vouchers";
// import BenefitsView from "./modules/Benefits/BenefitsView";
import UserSearcher from "./modules/Searcher/UserSearcher";
import HobbiesAndInterests from "./modules/Searcher/HobbiesAndInterests";
import PoliticasModal from "./modules/Politicas/PoliticasModal";
import BusquedaFirmas from "@jumbo/components/busqueda-firmas/BusquedaFirmas";

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
};

const flatten = array => array.reduce((a, b) => a.concat(b), []);

const fetchPermission = user => async permission => {
  // Simulate a delay from a request
  let privileges = flatten(user.roles.map(r => r.privileges.map(p => p.name)));
  return privileges.includes(permission);
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);

  const { setThemeType } = useContext(AppContext);
  const mode = THEME_TYPES;

  const colorMode = sessionStorage.getItem("colorMode");
  useEffect(() => {
    if (colorMode !== "dark") {
      setThemeType(mode.LIGHT);
    } else if (colorMode === "dark") {
      setThemeType(mode.DARK);
    }
  }, [colorMode, mode.LIGHT, mode.DARK, setThemeType]);

  return (
    <React.Fragment>
      <PermissionProvider fetchPermission={fetchPermission(authUser)}>
        <Switch>
          <RestrictedRoute path="/usuarios" component={UsersModule} />
          <RestrictedRoute
            path="/licencias/:id"
            component={LicenceDetailView}
          />
          <RestrictedRoute path="/licencias" component={LicencesModule} />
          <RestrictedRoute
            path="/proyectos/:id"
            component={ProjectDetailView}
          />
          <RestrictedRoute path="/proyectos" component={ProjectsModule} />
          <RestrictedRoute
            path="/capacitaciones/:id"
            component={TrainingDetailView}
          />
          <RestrictedRoute path="/capacitaciones" component={TrainingsModule} />
          <RestrictedRoute
            path="/carga-de-horas"
            component={TimeTrackingModule}
          />
          <RestrictedRoute
            path="/aprobacion-de-horas"
            component={TimeTrackingApproverModule}
          />
          <RestrictedRoute
            path="/admin-horas"
            component={TimeTrackingAdminModule}
          />
          <RestrictedRoute
            path="/admin-asignar-horas"
            component={AdminAddHoursModule}
          />
          <RestrictedRoute
            path="/admin-asignar-feriados"
            component={AddHolidayHours}
          />
          <RestrictedRoute path="/buscador" component={UserSearcher} />
          <RestrictedRoute path="/politicas" component={PoliticasModal} />
          <RestrictedRoute
            path="/hobbies-intereses/:name"
            component={HobbiesAndInterests}
          />
          <RestrictedRoute path="/perfil/editar" component={UserProfileEdit} />
          <RestrictedRoute path="/perfil/:id" component={UserProfileView} />
          <RestrictedRoute path="/perfil" component={UserProfileView} />
          <RestrictedRoute path="/firmas" component={BusquedaFirmas} />
          <RestrictedRoute exact path="/noticias" component={News} />
          <RestrictedRoute path="/noticias/:id" component={NewsDetailView} />
          <RestrictedRoute path="/agregar-noticia" component={AddNews} />
          <RestrictedRoute path="/editar-noticia/:id" component={EditNew} />
          {/* <RestrictedRoute path="/beneficios" component={BenefitsView} />
          <RestrictedRoute path="/vouchers" component={Vouchers} /> */}
          <RestrictedRoute path="/beneficios" component={Benefits} />
          <RestrictedRoute
            path="/beneficios/:id"
            component={BenefitsDetailView}
          />
          {/* <RestrictedRoute path="/agregar-beneficio" component={AddBenefits} /> */}
          {/* <RestrictedRoute path="/editar-beneficio/:id" component={EditBenefit} /> */}
          <Route path="/signin" component={Login} />
          <RestrictedRoute path="/" component={Intranet} />
          <Route component={Error404} />
        </Switch>
      </PermissionProvider>
    </React.Fragment>
  );
};

export default Routes;
