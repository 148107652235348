import "./dialog.css";

import React from "react";

export default function DisplayFirma({ firma, setFirmaDisplayed }) {
  function modificarDimensionesEnPx(texto) {
    const anchoPantalla = window.innerWidth;

    // Establecer el factor de multiplicación según el tamaño de la pantalla
    let factorMultiplicacion;

    if (anchoPantalla < 470) {
      factorMultiplicacion = 1;
    } else if (anchoPantalla < 660) {
      factorMultiplicacion = 1.5;
    } else {
      factorMultiplicacion = 2;
    }

    // Regex para encontrar valores en pixeles
    const regex = /(\d+)px/g;

    // Reemplazar los valores encontrados multiplicándolos por el factor segun el tamaño de la pantalla
    return texto.replace(regex, p1 => {
      // p1 es el número encontrado (sin el "px"), lo multiplicamos por el factor de multiplicacion
      const nuevoValor = parseInt(p1) * factorMultiplicacion;
      return `${nuevoValor}px`; // Se devuelve el valor multiplicado con px al final
    });
  }

  //Versión del metodo usando un blob para poder copiar la firma directo al portapapeles,
  //javascript no permite acceder al portapapeles sin certificado HTTPS por eso queda comentado.

  /*const copiarFirma = async () => {
        try {
            if (typeof ClipboardItem === 'undefined') {

                
                throw new Error('ClipboardItem no está disponible en este navegador, puede ser que se trate de un navegador antiguo o que el sitio no cuente con un certificado SSL válido.');
            }

            // Creamos un objeto Blob con la firma que devolvió la API (sin modificar tamaño)
            const htmlContent = firma;
            const blob = new Blob([htmlContent], { type: 'text/html' }); //El HTML se guarda en un blob porque así el portapapeles lo puede interpretar como elemento y no texto

        
            // eslint-disable-next-line no-undef    
            const clipboardItem = new ClipboardItem({
                'text/html': blob
            }); //Se hace un objeto de portapapeles con el blob guardado dentro

            //Usamos la api navigator.clipboard que recibe el blob y lo guarda en el portapapeles
            await navigator.clipboard.write([clipboardItem]);

            setFirmaCopiada(true);
        } catch (error) {
            setFirmaCopiada(false);
            console.error(error);
        }*/

  const copiarFirma = () => {
    const nuevaVentana = window.open("about:blank", "_blank");

    nuevaVentana.name = "Firma";

    nuevaVentana.document.write(`
            <!DOCTYPE html>
            <html lang="es">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Nueva Pestaña</title>
                </head>
                <body>
                    ${firma} <!-- Aquí se inserta el contenido dinámico -->
                </body>
            </html>
                `);

    nuevaVentana.document.close();
  };

  return (
    <div className="dialogo-overlay">
      <div className="dialogo">
        {firma !== undefined ? (
          <div
            dangerouslySetInnerHTML={{
              __html: modificarDimensionesEnPx(firma)
            }}
          ></div>
        ) : (
          <div>
            <p>
              Ocurrió un error al generar su firma, por favor intentelo de nuevo
              más tarde.
            </p>
          </div>
        )}

        <div className="dialogo-buttons">
          <button className="button copiar" onClick={copiarFirma}>
            Copiar firma<i className="fa-regular fa-copy"></i>
          </button>
          <button
            className="button cancelar"
            onClick={() => setFirmaDisplayed(false)}
          >
            Salir
          </button>
        </div>
      </div>
    </div>
  );
}
